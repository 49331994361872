import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from 'react-netlify-forms';
import { useForm } from 'react-hook-form';

import { Section, Container } from '@components/global';

const Contact = () => {
  const data = useStaticQuery(graphql`
    query {
      art_contact: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "contact" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1600) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const { register, handleSubmit, reset, errors } = useForm();
  const netlify = useNetlifyForm({
    name: 'contact',
    action: '/',
    honeypotName: 'bot-field',
    onSuccess: (response, context) => {
      reset();
    },
  });
  const onSubmit = data => netlify.handleSubmit(null, data);

  const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+.[A-Z]{2,4}$/i;

  return (
    <Section id="contactar" accent="secondary">
      <Container style={{ position: 'relative' }}>
        <h1>Contactar</h1>
        <ContactForm>
          <NetlifyFormProvider {...netlify}>
            <NetlifyFormComponent onSubmit={handleSubmit(onSubmit)}>
              <Honeypot />

              <div className="row">
                <label htmlFor="name">
                  Nombre:{' '}
                  {errors.name && (
                    <span className="silent-errors">{errors.name.message}</span>
                  )}
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  aria-label="name"
                  ref={register({
                    required: 'Nombre obligatorio',
                  })}
                />
              </div>
              <div className="row">
                <label htmlFor="email">
                  eMail:{' '}
                  {errors.email && (
                    <span className="silent-errors">
                      {errors.email.message}
                    </span>
                  )}
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  aria-label="email"
                  ref={register({
                    required: 'eMail obligatorio',
                    pattern: {
                      value: EMAIL_REGEX,
                      message: 'Dirección de eMail no válida',
                    },
                  })}
                />
              </div>
              <div className="row">
                <label htmlFor="message">
                  Mensaje:{' '}
                  {errors.message && (
                    <span className="silent-errors">
                      {errors.message.message}
                    </span>
                  )}
                </label>
                <textarea
                  name="message"
                  id="message"
                  aria-label="message"
                  ref={register({
                    required: 'Mensaje obligatorio',
                  })}
                />
              </div>
              <div className="row">
                <button type="submit">Enviar</button>
              </div>
              <div className="row">
                {netlify.success && (
                  <p>
                    ¡Gracias por ponerte en contacto! En breve te contestaremos.
                  </p>
                )}
                {netlify.error && (
                  <p>
                    Lo sentimos, algo no ha funcionado. Prueba a enviar de
                    nuevo.
                  </p>
                )}
              </div>
            </NetlifyFormComponent>
          </NetlifyFormProvider>
        </ContactForm>
        <Art>
          <Img fluid={data.art_contact.childImageSharp.fluid} />
        </Art>
        <ArtMobile>
          <Img fluid={data.art_contact.childImageSharp.fluid} />
        </ArtMobile>
      </Container>
    </Section>
  );
};

const Art = styled.figure`
  width: 800px;
  margin: -80px 0;
  position: absolute;
  top: 0;
  left: 70%;

  @media (max-width: ${props => props.theme.screen.lg}) {
    top: 20%;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    display: none;
  }
`;

const ArtMobile = styled.figure`
  width: 100%;
  margin: 0;
  display: none;
  margin-top: 64px;
  margin-bottom: -60%;

  @media (max-width: ${props => props.theme.screen.md}) {
    display: block;
  }
`;

const ContactForm = styled.div`
  max-width: 400px;

  .row {
    margin-top: 20px;
  }

  .silent-errors {
    ${props => props.theme.font_size.small};
    color: ${props => props.theme.color.black.light};
  }

  label {
    display: block;
    font-family: ${props => props.theme.font.primary};
    ${props => props.theme.font_size.regular};
  }

  input,
  textarea {
    width: 100%;
    ${props => props.theme.font_size.small};
  }

  button {
    margin-top: 20px;
    background: #000;
    font-family: ${props => props.theme.font.primary};
    ${props => props.theme.font_size.regular};
    color: ${props => props.theme.color.white.regular};
    padding: 10px;
    transition: all 0.1s ease-in-out;
    :hover {
      color: ${props => props.theme.color.yellow};
    }
  }
`;

export default Contact;
