import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { Section, Container } from '@components/global';

import ExternalLink from '@common/ExternalLink';

const Info = () => (
  <StaticQuery
    query={graphql`
      query {
        art_bautismo: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "ideas" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_cursos: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "learn_yourself" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }

        art_crece: file(
          sourceInstanceName: { eq: "art" }
          name: { eq: "fast" }
        ) {
          childImageSharp {
            fluid(maxWidth: 760) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <Section id="info">
        <Container>
          <Grid>
            <div>
              <h2>Bautismos de buceo en Islas Cíes</h2>
              <p>
                Haz tu{' '}
                <ExternalLink href="https://buceoislascies.es/producto/bautizo-de-buceo">
                  bautizo de buceo en las Islas Cíes
                </ExternalLink>
                , el mejor lugar de Galicia para iniciarte en el buceo. La mejor
                localización posible en las{' '}
                <ExternalLink href="https://www.islascies.eu/">
                  Islas Cíes
                </ExternalLink>
                , al lado del muelle, con arrecifes y mucha variedad de vida.
                Descubrirás una muestra de la vida marina de las Rías Baixas y
                todo su ecosistema.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_bautismo.childImageSharp.fluid} />
            </Art>
          </Grid>
          <Grid inverse>
            <Art>
              <Img fluid={data.art_cursos.childImageSharp.fluid} />
            </Art>
            <div>
              <h2>Cursos PADI Vigo (Pontevedra, Galicia)</h2>
              <p>
                Obtén tu título{' '}
                <ExternalLink href="https://buceoislascies.es/producto/open-water-diver/">
                  PADI Open Water Scuba Diver
                </ExternalLink>{' '}
                con los mejores profesionales en Vigo (Pontevedra). Podrás
                bucear en cualquier parte del mundo. Ya no tendrás excusa para
                poder descubrir la vida marina en el lugar del mundo en el que
                te encuentres. Mejora tus viajes añadiendo la actividad de
                buceo. Aprenderás de una forma sencilla y flexible, para que
                puedas seguir con tu vida mientras aprendes a bucear. ¡En menos
                de una semana serás un buceador titulado!
              </p>
            </div>
          </Grid>
          <Grid>
            <div>
              <h2>Aprende y crece como buceador</h2>
              <p>
                Si ya tienes titulación de buceo, sigue aprendiendo y mejorando.
                <br />
                Avanza hacia Advanced Open Water Diver, Rescue Diver o
                Divemaster. Incluso puede que te interesen algunas
                especialidades como buceo profundo, buceo en pecios o buceo
                nocturno. Fórmate en lo que estés más interesado con los mejores{' '}
                <ExternalLink href="https://buceoislascies.es/cursos-padi/">
                  cursos PADI en Galicia
                </ExternalLink>
                .
                <br />
                Además no olvides de mejorar tus habilidades de buceo como
                aleteo, flotabilidad o repasa todas las configuraciones y
                posibilidades que ofrece el buceo. Sin olvidarte de mejorar y
                adquirir tu propio equipo de buceo para que no tengas que
                alquilarlo.
              </p>
            </div>
            <Art>
              <Img fluid={data.art_crece.childImageSharp.fluid} />
            </Art>
          </Grid>
        </Container>
      </Section>
    )}
  />
);

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 2fr;
  grid-gap: 40px;
  text-align: right;
  align-items: center;
  justify-items: center;
  margin: 24px 0;

  ${props =>
    props.inverse &&
    `
    text-align: left;
    grid-template-columns: 2fr 3fr;
  `}

  h2 {
    margin-bottom: 16px;
  }

  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    text-align: left;
    margin-bottom: 96px;

    &:last-child {
      margin-bottom: 24px;
    }

    ${props =>
      props.inverse &&
      `
        ${Art} {
          order: 2;
        }
    `}
  }
`;

const Art = styled.figure`
  margin: 0;
  max-width: 380px;
  width: 100%;
`;

export default Info;
